// ** Packages **
import { useEffect, useState } from "react";
import {
  Control,
  UseFormRegister,
  UseFormSetValue,
  useWatch,
} from "react-hook-form";
import { useParams } from "react-router-dom";

// ** Components **
import {
  AsyncSelectField,
  TextField,
  ToggleButtonField,
} from "components/FormField";

// ** Redux **
import useFetchStatesOptions from "hooks/useFetchStatesOptions";

export type AddressPropsType = {
  control?: Control<any>;
  register?: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  errors?: { message?: string; [key: string]: any };
  initialAddress?: boolean;
  shippingDisabled?: boolean;
};

const Address = (props: AddressPropsType) => {
  const { id } = useParams();
  const {
    register,
    errors,
    control,
    setValue,
    initialAddress = false,
    shippingDisabled = false,
  } = props;

  const { getStatesOption, isLoading: stateLoading } = useFetchStatesOptions();
  const formData = useWatch({ control });

  const {
    same_as_billing,
    billing_address,
    billing_city,
    billing_state_id,
    billing_zip,
    shipping_address,
    shipping_city,
    shipping_state_id,
    shipping_zip,
  } = formData;

  const [addShipping, setAddShipping] = useState<boolean>(
    id && shipping_address?.length
  );

  useEffect(() => {
    if (
      initialAddress &&
      !shipping_address &&
      !shipping_city &&
      !shipping_state_id &&
      !shipping_zip
    ) {
      setAddShipping(false);
    }
  }, [initialAddress]);
  useEffect(() => {
    setValue(
      "shipping_address",
      shipping_address ??
        (same_as_billing ? billing_address : shipping_address ?? null)
    );
    setValue(
      "shipping_city",
      shipping_city ?? (same_as_billing ? billing_city : shipping_city ?? null)
    );
    setValue(
      "shipping_state_id",
      shipping_state_id ??
        (same_as_billing ? billing_state_id : shipping_state_id ?? null)
    );
    setValue(
      "shipping_zip",
      shipping_zip ?? (same_as_billing ? billing_zip : shipping_zip ?? null)
    );
  }, [same_as_billing]);

  useEffect(() => {
    if (!same_as_billing) {
      setValue("shipping_address", null);
      setValue("shipping_city", null);
      setValue("shipping_state_id", null);
      setValue("shipping_zip", null);
    }
  }, [same_as_billing]);

  return (
    <>
      <div className="w-full px-[10px]">
        <div className="addressField__wrapper relative">
          <TextField
            name="billing_address"
            label="Address"
            register={register}
            onChange={() => {
              if (same_as_billing) {
                setValue("shipping_address", billing_address);
              }
            }}
            placeholder="Enter address"
            errors={errors?.billing_address}
            fieldWrapperClassName="carperFieldBox"
            fieldBGClassName="bg-bgWhiteCarpet"
          />
          {!shippingDisabled && !addShipping ? (
            <span
              onClick={() => setAddShipping(true)}
              className="text-[14px] text-primaryColor font-AcuminPro__Regular cursor-pointer absolute top-[12px] right-[15px] z-[5] hover:underline"
            >
              +Add Shipping
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="fieldCol w-1/3 px-[10px] 4xl:w-full xl:w-1/3 sm:w-full">
        <TextField
          name="billing_city"
          autoComplete="off"
          label="City"
          onChange={() => {
            if (same_as_billing) {
              setValue("shipping_city", billing_city);
            }
          }}
          placeholder="Enter City"
          register={register}
          errors={errors?.billing_city}
          fieldWrapperClassName="carperFieldBox"
          fieldBGClassName="bg-bgWhiteCarpet"
        />
      </div>
      <div className="fieldCol w-1/3 px-[10px] 4xl:w-full xl:w-1/3 sm:w-full">
        <AsyncSelectField
          key={billing_state_id?.value}
          name="billing_state_id"
          placeholder="Select State"
          control={control}
          label="State"
          onChange={(e) => {
            if (same_as_billing) {
              setValue("shipping_state_id", e);
            }
          }}
          serveSideSearch
          getOptions={getStatesOption}
          isLoading={stateLoading}
          errors={errors?.billing_state_id}
          fieldWrapperClassName="carperFieldBox"
          fieldBGClassName="bg-bgWhiteCarpet"
        />
      </div>
      <div className="fieldCol w-1/3 px-[10px] 4xl:w-full xl:w-1/3 sm:w-full">
        <TextField
          name="billing_zip"
          autoComplete="off"
          label="Zip"
          placeholder="Enter Zip"
          register={register}
          onChange={() => {
            if (same_as_billing) {
              setValue("shipping_zip", billing_zip);
            }
          }}
          errors={errors?.billing_zip}
          fieldWrapperClassName="carperFieldBox"
          fieldBGClassName="bg-bgWhiteCarpet"
        />
      </div>

      {!shippingDisabled && (addShipping || same_as_billing) ? (
        <>
          <div className="w-full px-[10px]">
            <div className="inline-block mr-[25px] mb-[10px]">
              <ToggleButtonField
                register={register}
                name="same_as_billing"
                label="Same As Above"
              />
            </div>
          </div>
          <div className="w-full px-[10px]">
            <div className="addressField__wrapper relative">
              <TextField
                name="shipping_address"
                label="Shipping Address"
                register={register}
                placeholder="Enter address"
                errors={errors?.shipping_address}
                fieldWrapperClassName="carperFieldBox"
                fieldBGClassName="bg-bgWhiteCarpet"
              />
            </div>
          </div>
          <div className="w-1/3 px-[10px] 4xl:w-full xl:w-1/3 sm:w-full">
            <TextField
              name="shipping_city"
              autoComplete="off"
              label="Shipping City"
              placeholder="Enter City"
              register={register}
              errors={errors?.shipping_city}
              fieldWrapperClassName="carperFieldBox"
              fieldBGClassName="bg-bgWhiteCarpet"
            />
          </div>
          <div className="w-1/3 px-[10px] 4xl:w-full xl:w-1/3 sm:w-full">
            <AsyncSelectField
              key={shipping_state_id?.value}
              name="shipping_state_id"
              label="Shipping State"
              placeholder="Select State"
              serveSideSearch
              control={control}
              isLoading={stateLoading}
              getOptions={getStatesOption}
              errors={errors?.shipping_state_id}
              fieldWrapperClassName="carperFieldBox"
              fieldBGClassName="bg-bgWhiteCarpet"
            />
          </div>
          <div className="w-1/3 px-[10px] 4xl:w-full xl:w-1/3 sm:w-full">
            <TextField
              name="shipping_zip"
              autoComplete="off"
              label="Shipping Zip"
              placeholder="Enter Zip"
              register={register}
              errors={errors?.shipping_zip}
              fieldWrapperClassName="carperFieldBox"
              fieldBGClassName="bg-bgWhiteCarpet"
            />
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Address;
