// ** Packages **
import { useMemo } from "react";
import { useSelector } from "react-redux";

// ** Redux **
import { getCurrentUser } from "redux/slices/authSlice";
import { getCurrentUserPermissions } from "redux/slices/permissionSlice";

// ** constant **
import { ACTION, PERMISSION_MODULE } from "constants/entity.constant";
import { ROLE_TYPE } from "constants/role.constant";

const useIsAuthorized = () => {
  // ** Redux **
  const currentUserPermissions: string[] = useSelector(
    getCurrentUserPermissions
  );
  const userInfo = useSelector(getCurrentUser);
  const isAuthorized = useMemo(() => {
    return ({
      permissionName,
      moduleName,
    }: {
      permissionName: ACTION;
      moduleName: PERMISSION_MODULE;
    }) => {
      if (userInfo?.userRole?.toString() === ROLE_TYPE.ADMIN?.toString()) {
        return true;
      }
      if (!permissionName && !moduleName) {
        return false;
      }
      const checkPermission = (
        module: PERMISSION_MODULE,
        permission: ACTION
      ) => {
        const checkPermission = `${module}_${permission}`;
        return currentUserPermissions.find((val) => val === checkPermission);
      };
      const checkPermissions = () =>
        checkPermission(moduleName, permissionName);

      return checkPermissions();
    };
  }, []);

  return {
    isAuthorized,
    isAdminRole: userInfo?.userRole?.toString() === ROLE_TYPE.ADMIN?.toString(),
  };
};

export default useIsAuthorized;
