// ** Type **
import { IconTypes } from "components/Icon/types";

// ** constant **
import { PRIVATE_NAVIGATION } from "constants/navigation.constant";
import { ROLE_TYPE } from "constants/role.constant";

// ** Types **
export type SettingMenuItemType = {
  text: string;
  id: string;
  activeMatchValue?: string;
  icon: IconTypes;
  path: string;
  subItems?: SettingMenuItemType[];
  accessBy?: string;
};

export const SIDEBAR_DATA: SettingMenuItemType[] = [
  {
    text: "General Settings",
    id: "general-settings",
    icon: "caretDown",
    path: PRIVATE_NAVIGATION.SETTING.GENERAL_SETTING.VIEW,
    subItems: [
      {
        text: "Reference Type",
        icon: "caretDown",
        id: "reference-type",
        path: PRIVATE_NAVIGATION.SETTING.GENERAL_SETTING.REF_SRC_TYPE,
      },

      {
        text: "Factor Type",
        icon: "caretDown",
        id: "factor-type",
        path: PRIVATE_NAVIGATION.SETTING.GENERAL_SETTING.FACTOR,
      },
    ],
  },
  {
    text: "Lead Setting",
    id: "lead-settings",
    icon: "caretDown",
    path: PRIVATE_NAVIGATION.SETTING.LEAD_SETTING.VIEW,
    subItems: [
      {
        text: "Job Status",
        icon: "caretDown",
        id: "job-status",
        path: PRIVATE_NAVIGATION.SETTING.LEAD_SETTING.JOB_STATUS,
      },
      {
        text: "Room Type",
        icon: "caretDown",
        id: "room-type",
        path: PRIVATE_NAVIGATION.SETTING.LEAD_SETTING.ROOM_TYPE,
      },
    ],
  },
  {
    text: "Product Settings",
    icon: "caretDown",
    id: "product-settings",
    path: PRIVATE_NAVIGATION.SETTING.PRODUCT.VIEW,
    subItems: [
      {
        text: "Product Code",
        icon: "caretDown",
        id: "product-code",
        path: PRIVATE_NAVIGATION.SETTING.PRODUCT.CODE,
      },
      {
        text: "Product Type",
        icon: "caretDown",
        id: "product-code",
        path: PRIVATE_NAVIGATION.SETTING.PRODUCT.TYPE,
      },
    ],
  },
  {
    text: "Order Settings",
    id: "order-settings",
    icon: "caretDown",
    path: PRIVATE_NAVIGATION.SETTING.ORDER_SETTING.VIEW,
    subItems: [
      {
        text: "Job Status",
        icon: "caretDown",
        id: "order-job-status",
        path: PRIVATE_NAVIGATION.SETTING.ORDER_SETTING.JOB_STATUS,
      },
      {
        text: "Job Type",
        icon: "caretDown",
        id: "order-job-type",
        path: PRIVATE_NAVIGATION.SETTING.ORDER_SETTING.JOB_TYPE,
      },
      {
        text: "Item Status",
        icon: "caretDown",
        id: "order-item-status",
        path: PRIVATE_NAVIGATION.SETTING.ORDER_SETTING.ITEM_STATUS,
      },
      {
        text: "Payment Code",
        icon: "caretDown",
        id: "payment-code",
        path: PRIVATE_NAVIGATION.SETTING.ORDER_SETTING.PAYMENT_CODE,
      },
    ],
  },
  {
    text: "PO Settings",
    id: "po-settings",
    icon: "caretDown",
    path: PRIVATE_NAVIGATION.SETTING.PURCHASE_ORDER_SETTING.VIEW,
    subItems: [
      {
        text: "Item Status",
        icon: "caretDown",
        id: "purchase-order-item-status",
        path: PRIVATE_NAVIGATION.SETTING.PURCHASE_ORDER_SETTING.ITEM_STATUS,
      },
      {
        text: "Shipping Option",
        icon: "caretDown",
        id: "shipping-option",
        path: PRIVATE_NAVIGATION.SETTING.PURCHASE_ORDER_SETTING.SHIPPING_OPTION,
      },
    ],
  },
  {
    text: "Account Settings",
    id: "ac-settings",
    icon: "caretDown",
    path: PRIVATE_NAVIGATION.SETTING.ACCOUNT_SETTING.VIEW,
    subItems: [
      {
        text: "Account Type",
        icon: "caretDown",
        id: "purchase-order-item-status",
        path: PRIVATE_NAVIGATION.SETTING.ACCOUNT_SETTING.ACCOUNT_TYPE,
      },
      {
        text: "G/L Account",
        icon: "caretDown",
        id: "shipping-option",
        path: PRIVATE_NAVIGATION.SETTING.ACCOUNT_SETTING.GL_ACCOUNT,
      },
    ],
  },

  {
    text: "Vendors",
    icon: "caretDown",
    id: "vendors",
    path: PRIVATE_NAVIGATION.SETTING.VENDOR.VIEW,
  },
  {
    path: PRIVATE_NAVIGATION.SETTING.INSTALLER.VIEW,
    icon: "caretDown",
    id: "installer",
    text: "Installer",
  },
  {
    path: PRIVATE_NAVIGATION.SETTING.PRICING.VIEW,
    icon: "caretDown",
    id: "pricing",
    text: "A-J Pricing",
  },
  {
    path: PRIVATE_NAVIGATION.SETTING.ROLES_PERMISSION.VIEW,
    icon: "caretDown",
    id: "role_permission",
    text: "Roles & Permission",
    accessBy: ROLE_TYPE.ADMIN,
  },
  {
    path: PRIVATE_NAVIGATION.SETTING.IMPORT.VIEW,
    icon: "caretDown",
    id: "product_import",
    text: "Product Import",
    accessBy: ROLE_TYPE.ADMIN,
  },
];
