export const PUBLIC_NAVIGATION = Object.freeze({
  LOGIN: "/auth/login",
  FORGOT_PASSWORD: "/auth/forgot-password",
  NOT_AUTHORIZED: "/not-authorized",
  NOT_FOUND_PAGE: "/404",
  STATIC: "/static",
  SET_PASSWORD: "/auth/set-password",
  RESET_PASSWORD: "/auth/reset-password",
});

export const PRIVATE_NAVIGATION: { [key: string]: any } = Object.freeze({
  DASHBOARD: { VIEW: "/" },
  LEAD: {
    VIEW: "/lead",
    ADD: "/lead/add",
    EDIT: "/lead/:id",
  },
  CUSTOMER: {
    VIEW: "/customer",
    ADD: "/customer/add",
    EDIT: "/customer/:id",
  },
  ORDER: {
    VIEW: "/order",
    ADD: "/order/add",
    EDIT: "/order/:id",
    REPORT: "/order?report=true",
  },
  PURCHASE_ORDER: {
    VIEW: "/purchase-order",
    ADD: "/purchase-order/add",
    EDIT: "/purchase-order/:id",
  },

  PRODUCT: {
    VIEW: "/product",
    ADD: "/product/add",
    EDIT: "/product/:id",
  },
  ESTIMATE: {
    VIEW: "/estimate",
    ADD: "/estimate/add",
    EDIT: "/estimate/:id",
  },
  INSTALLER: {
    VIEW: "/installer",
    ADD: "/installer/add",
    EDIT: "/installer/:id",
  },
  INVOICE: {
    VIEW: "/invoice",
    ADD: "/invoice/add",
    EDIT: "/invoice/:id",
  },
  TEAM: {
    VIEW: "/team",
    ADD: "/team/add",
    EDIT: "/team/:id",
  },
  REPORT: {
    VIEW: "/report",
    ADD: "/report/add",
    EDIT: "/report/:id",
    INSTALLER_HISTORY: { VIEW: "/report/installer" },
    SALES_COMMISSION: {
      VIEW: "/report/commission",
      ADD: "/report/commission/add",
      EDIT: "/report/commission/:id",
      REPORT: "/report/commission?report=true",
    },
  },
  SETTING: {
    VIEW: "/setting",
    GENERAL_SETTING: {
      VIEW: "/setting/general",
      REF_SRC_TYPE: "/setting/general/ref-src-type",
      FACTOR: "/setting/general/factor",
    },
    ACCOUNT_SETTING: {
      VIEW: "/setting/account",
      ACCOUNT_TYPE: "/setting/account/account-type",
      GL_ACCOUNT: "/setting/account/gl-account",
    },
    LEAD_SETTING: {
      VIEW: "/setting/lead",
      JOB_STATUS: "/setting/lead/job-status",
      ROOM_TYPE: "/setting/lead/room-type",
    },
    PRODUCT: {
      VIEW: "/setting/product",
      CODE: "/setting/product/code",
      TYPE: "/setting/product/product-type",
    },
    ORDER_SETTING: {
      VIEW: "/setting/order",
      JOB_STATUS: "/setting/order/job-status",
      JOB_TYPE: "/setting/order/job-type",
      ITEM_STATUS: "/setting/order/item-status",
      PAYMENT_CODE: "/setting/order/payment-code",
    },
    PURCHASE_ORDER_SETTING: {
      VIEW: "/setting/purchase-order",
      ITEM_STATUS: "/setting/purchase-order/item-status",
      SHIPPING_OPTION: "/setting/purchase-order/shipping-option",
    },
    VENDOR: { VIEW: "/setting/vendor", CREATE: "/setting/vendor?create=true" },
    INSTALLER: { VIEW: "/setting/installer" },
    PRICING: { VIEW: "/setting/pricing" },
    ROLES_PERMISSION: { VIEW: "/setting/role" },
    IMPORT: { VIEW: "/setting/import" },
    GL_ACCOUNT: { VIEW: "/gl-account" },
  },
  CALENDAR: {
    VIEW: "/calender",
  },
});
